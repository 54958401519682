const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.addEventListener("load", () => {
      resolve(src);
    });
    script.addEventListener("error", () => {
      reject(src);
    });
    document.body.appendChild(script);
  });
};

const loadCSS = (src) => {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.href = src;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.addEventListener("load", () => {
      resolve(src);
    });
    link.addEventListener("error", () => {
      reject(src);
    });
    document.head.appendChild(link);
  });
};

const debounce = (eventType, callback) => {
  window.addEventListener(eventType, (evt) => {
    clearTimeout(window.eventComplete);
    window.eventComplete = setTimeout(() => {
      callback.apply();
    }, 250);
  });
};

export {
  loadScript,
  loadCSS,
  debounce
}
